import styled from "styled-components"

import LightBulbOn from "src/ui/icons/lightbulb-on.svg"
import { ImportantBanner } from "src/ui/MBanner/ImportantBanner"
import { MBanner } from "src/ui/MBanner/MBanner"

export function MBannerDemo() {
  return (
    <Grid>
      <MBanner>This is a default banner</MBanner>

      <MBanner type="error">This is a 'critical' banner</MBanner>

      <MBanner type="error">This is an 'emergency' banner</MBanner>

      <MBanner type="info">This is an 'info' banner</MBanner>

      <MBanner type="warning">This is a 'warning' banner</MBanner>

      <MBanner type="good">This is an 'ok' banner</MBanner>

      <MBanner type="neutral">This is a 'neutral' banner</MBanner>
      <MBanner
        type="neutral"
        buttons={[{ label: "Click me", onClick: () => undefined }]}
        title="This is a banner"
        icon={<LightBulbOn width={"24"} height={"24"} />}
      >
        With title, icon, body and button
      </MBanner>
      <MBanner
        type="neutral"
        title="This is a banner"
        icon={<LightBulbOn width={"24"} height={"24"} />}
      >
        With title, icon and body
      </MBanner>
      <MBanner
        type="neutral"
        title="This is a banner with title and icon"
        icon={<LightBulbOn width={"24"} height={"24"} />}
      ></MBanner>
      <MBanner
        type="neutral"
        title="This is a banner with title, icon and button"
        buttons={[{ label: "Click me", onClick: () => undefined }]}
        icon={<LightBulbOn width={"24"} height={"24"} />}
      ></MBanner>
      <MBanner type="neutral" title="This is a banner">
        With title and body
      </MBanner>
      <MBanner
        type="neutral"
        buttons={[
          {
            label: "No",
            onClick: () => undefined,
            buttonProps: { color: "destructive", variant: "primary" },
          },
          {
            label: "Yes",
            onClick: () => undefined,
            buttonProps: { color: "default", variant: "primary" },
          },
        ]}
        title="This is a banner"
        icon={<LightBulbOn width={"24"} height={"24"} />}
      >
        With title, icon, body and multiple buttons with button prop overrides
      </MBanner>

      <MBanner
        type="info"
        title="Horizontal banner"
        icon={<LightBulbOn width={"24"} height={"24"} />}
        horizontal
      >
        This is a horizontal banner, all is horizontal expect the buttons
      </MBanner>

      <MBanner
        type="info"
        title="Horizontal banner"
        icon={<LightBulbOn width={"24"} height={"24"} />}
        horizontal
        buttons={[
          {
            label: "No",
            onClick: () => undefined,
            buttonProps: { color: "destructive", variant: "primary" },
          },
          {
            label: "Yes",
            onClick: () => undefined,
            buttonProps: { color: "default", variant: "primary" },
          },
        ]}
      >
        This is a horizontal banner, all is horizontal expect the buttons
      </MBanner>

      <ImportantBanner title="Important banner">
        This is a banner with the important icon. It's also horizontal by
        default
      </ImportantBanner>
    </Grid>
  )
}

const Grid = styled.div`
  display: grid;
  gap: 1rem;
`
