import { useState } from "react"

import { Divider } from "src/ui/Divider/Divider"
import HomeIcon from "src/ui/icons/home.svg"
import { MText } from "src/ui/MText"
import { MTextField } from "src/ui/MTextField/MTextField"
import { spacing } from "src/ui/spacing"

export function MTextFieldDemo() {
  const [showStartAdornment, setShowStartAdornment] = useState(false)
  const [showEndAdornment, setShowEndAdornment] = useState(false)
  const [showClearbutton, setShowClearButton] = useState(false)
  const [helperText, setHelpterText] = useState("")

  const [input, setInput] = useState("")

  function handleInputClear() {
    setInput("")
  }

  const startAdornment = <HomeIcon width={18} />

  const endAdornment = <HomeIcon width={18} />

  return (
    <div>
      <div>
        <label>
          <input
            type="checkbox"
            checked={showClearbutton}
            onChange={(e) => setShowClearButton(e.target.checked)}
          />
          Show clear button
        </label>
        <br />
        <label>
          <input
            type="checkbox"
            checked={showStartAdornment}
            onChange={(e) => setShowStartAdornment(e.target.checked)}
          />
          Show start adornment
        </label>
        <label>
          <input
            type="checkbox"
            checked={showEndAdornment}
            onChange={(e) => setShowEndAdornment(e.target.checked)}
          />
          Show end adornment
        </label>
      </div>
      <MTextField
        label="Helper text"
        value={helperText}
        onChange={setHelpterText}
      />

      <Divider $margin={spacing.XL} />

      <MText variant="heading3" marginBottom={spacing.M}>
        Default
      </MText>
      <MTextField
        label="Default"
        value={input}
        onChange={setInput}
        onClear={showClearbutton ? handleInputClear : undefined}
        helperText={helperText}
        startAdornment={showStartAdornment && startAdornment}
        endAdornment={showEndAdornment && endAdornment}
      />

      <Divider $margin={spacing.M} />

      <MText variant="heading3" marginBottom={spacing.M}>
        Without label
      </MText>
      <MTextField
        value={input}
        onChange={setInput}
        onClear={showClearbutton ? handleInputClear : undefined}
        helperText={helperText}
        startAdornment={showStartAdornment && startAdornment}
        endAdornment={showEndAdornment && endAdornment}
      />

      <Divider $margin={spacing.M} />

      <MText variant="heading3" marginBottom={spacing.M}>
        With placeholder
      </MText>
      <MTextField
        label="Label"
        placeholder="Placeholder"
        value={input}
        onChange={setInput}
        onClear={showClearbutton ? handleInputClear : undefined}
        helperText={helperText}
        startAdornment={showStartAdornment && startAdornment}
        endAdornment={showEndAdornment && endAdornment}
      />

      <Divider $margin={spacing.M} />

      <MText variant="heading3" marginBottom={spacing.M}>
        Required
      </MText>
      <form onSubmit={(e) => e.preventDefault()}>
        <MTextField
          label="Label"
          value={input}
          onChange={setInput}
          onClear={showClearbutton ? handleInputClear : undefined}
          helperText={helperText}
          startAdornment={showStartAdornment && startAdornment}
          endAdornment={showEndAdornment && endAdornment}
          required
        />
        <button>Submit</button>
      </form>

      <Divider $margin={spacing.M} />

      <MText variant="heading3" marginBottom={spacing.M}>
        Number
      </MText>
      <MTextField
        type="number"
        label="Number"
        value={input}
        onChange={setInput}
        onClear={showClearbutton ? handleInputClear : undefined}
        helperText={helperText}
        startAdornment={showStartAdornment && startAdornment}
      />

      <Divider $margin={spacing.M} />

      <MText variant="heading3" marginBottom={spacing.M}>
        With error (type "Error")
      </MText>
      <MTextField
        label="Error"
        value={input}
        onChange={setInput}
        onClear={showClearbutton ? handleInputClear : undefined}
        helperText={helperText}
        error={input === "Error" ? "An error occured" : ""}
        startAdornment={showStartAdornment && startAdornment}
      />

      <Divider $margin={spacing.M} />

      <MText variant="heading3" marginBottom={spacing.M}>
        Disabled
      </MText>
      <MTextField
        label="Disabled"
        value={input}
        onChange={setInput}
        onClear={showClearbutton ? handleInputClear : undefined}
        startAdornment={showStartAdornment && startAdornment}
        endAdornment={showEndAdornment && endAdornment}
        helperText={helperText}
        disabled
      />
    </div>
  )
}
