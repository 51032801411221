import { useState } from "react"

import { Divider } from "src/ui/Divider/Divider"
import HomeIcon from "src/ui/icons/home.svg"
import { MText } from "src/ui/MText"
import { MTextArea } from "src/ui/MTextArea/MTextArea"
import { MTextField } from "src/ui/MTextField/MTextField"
import { spacing } from "src/ui/spacing"

export function MTextAreaDemo() {
  const [showStartAdornment, setShowStartAdornment] = useState(false)
  const [showEndAdornment, setShowEndAdornment] = useState(false)
  const [showClearbutton, setShowClearButton] = useState(false)
  const [helperText, setHelperText] = useState("")
  const [minRows, setMinRows] = useState(1)
  const [maxRows, setMaxRows] = useState(10)

  const [input, setInput] = useState("")

  function handleInputClear() {
    setInput("")
  }

  const startAdornment = <HomeIcon width={18} />

  const endAdornment = <HomeIcon width={18} />

  return (
    <div>
      <div>
        <label>
          <input
            type="checkbox"
            checked={showClearbutton}
            onChange={(e) => setShowClearButton(e.target.checked)}
          />
          Show clear button
        </label>
        <br />
        <label>
          <input
            type="checkbox"
            checked={showStartAdornment}
            onChange={(e) => setShowStartAdornment(e.target.checked)}
          />
          Show start adornment
        </label>
        <label>
          <input
            type="checkbox"
            checked={showEndAdornment}
            onChange={(e) => setShowEndAdornment(e.target.checked)}
          />
          Show end adornment
        </label>
        <br />
        <label>
          Min rows
          <input
            type="number"
            value={minRows}
            onChange={(e) => setMinRows(parseInt(e.target.value))}
          />
        </label>
        <br />
        <label>
          Max rows
          <input
            type="number"
            value={maxRows}
            onChange={(e) => setMaxRows(parseInt(e.target.value))}
          />
        </label>
      </div>
      <MTextField
        label="Helper text"
        value={helperText}
        onChange={setHelperText}
      />

      <Divider $margin={spacing.M} />

      <MText variant="heading3" marginBottom={spacing.M}>
        Default
      </MText>
      <MTextArea
        label="Multiline"
        value={input}
        onChange={setInput}
        onClear={showClearbutton ? handleInputClear : undefined}
        startAdornment={showStartAdornment && startAdornment}
        endAdornment={showEndAdornment && endAdornment}
        helperText={helperText}
        minRows={minRows}
        maxRows={maxRows}
      />

      <Divider $margin={spacing.M} />

      <MText variant="heading3" marginBottom={spacing.M}>
        With placeholder
      </MText>
      <MTextArea
        label="Multiline"
        placeholder="Textarea"
        value={input}
        onChange={setInput}
        onClear={showClearbutton ? handleInputClear : undefined}
        startAdornment={showStartAdornment && startAdornment}
        endAdornment={showEndAdornment && endAdornment}
        helperText={helperText}
        minRows={minRows}
        maxRows={maxRows}
      />

      <Divider $margin={spacing.M} />

      <MText variant="heading3" marginBottom={spacing.M}>
        Required
      </MText>
      <form onSubmit={(e) => e.preventDefault()}>
        <MTextArea
          label="Label"
          value={input}
          onChange={setInput}
          onClear={showClearbutton ? handleInputClear : undefined}
          helperText={helperText}
          startAdornment={showStartAdornment && startAdornment}
          endAdornment={showEndAdornment && endAdornment}
          required
          minRows={minRows}
          maxRows={maxRows}
        />
        <button>Submit</button>
      </form>

      <Divider $margin={spacing.M} />

      <MText variant="heading3" marginBottom={spacing.M}>
        Disabled
      </MText>
      <MTextArea
        label="Multiline"
        value={input}
        onChange={setInput}
        onClear={showClearbutton ? handleInputClear : undefined}
        startAdornment={showStartAdornment && startAdornment}
        endAdornment={showEndAdornment && endAdornment}
        helperText={helperText}
        minRows={minRows}
        maxRows={maxRows}
        disabled
      />
    </div>
  )
}
