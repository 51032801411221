import { useState } from "react"

import { MNumberInput } from "src/ui/MNumberInput/MNumberInput"
import { MText } from "src/ui/MText"

export function MNumberInputDemo() {
  const [value, setValue] = useState(1)

  return (
    <div>
      <MText variant="subtitle">Unlimited MNumberInput</MText>
      <MNumberInput
        onChange={(v) => {
          setValue(v)
        }}
        value={value}
      />

      <MText variant="subtitle">Limited MNumberInput</MText>
      <MNumberInput
        onChange={(v) => {
          setValue(v)
        }}
        value={value}
        min={1}
        max={10}
      />
    </div>
  )
}
