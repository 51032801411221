import { LogOutput } from "src/components/RemoteDebugService/LogOutput"

export function SerialDeviceLog({
  open,
  data,
  onClear,
  hidden,
}: {
  open?: boolean
  data: string[]
  onClear: () => void
  hidden?: boolean
}) {
  if (hidden) return null

  return (
    <details open={open}>
      <summary>Serial log</summary>
      <LogOutput data={data} onClear={onClear} />
    </details>
  )
}
