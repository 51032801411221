import { DEFAULT_HOME_NAME } from "src/data/homes/types/homeTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useFetchHomes } from "src/data/organizations/queries/homeQueries"
import { Routes } from "src/router/routes"
import { Breadcrumbs } from "src/ui/Breadcrumbs/Breadcrumbs"

export function BreadcrumbDemo() {
  const { orgId } = useOrganization()
  const fetchHomes = useFetchHomes({
    orgId,
    filters: { limit: 3 },
  })

  if (fetchHomes.isLoading) {
    return <div>Loading homes...</div>
  }

  return (
    <article>
      {fetchHomes.data?.homes.map((h) => {
        const homeName = h.name || DEFAULT_HOME_NAME(h.home_id)
        return (
          <div key={h.home_id}>
            <div> » {h.name}</div>
            <Breadcrumbs
              items={[
                { label: "Homes", to: Routes.Homes.location() },
                { label: homeName, to: Routes.Home.location(h.home_id) },
                {
                  label: "Guests",
                  to: Routes.HomeGuests.location(h.home_id),
                },
              ]}
            />
            <Breadcrumbs
              items={[
                { label: "Homes", to: Routes.Homes.location() },
                { label: homeName, to: Routes.Home.location(h.home_id) },
                {
                  label: "Settings",
                  to: Routes.HomeSettings.location(h.home_id),
                },
              ]}
            />
          </div>
        )
      })}
    </article>
  )
}
