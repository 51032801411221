import { useState } from "react"
import styled from "styled-components"

import { NonLiveBanner } from "src/components/Homes/HomeDetails/Overview/NonLiveBanner"
import { divider } from "src/ui/colors"
import { ExpandableAttentionCard } from "src/ui/ExpandableSection/ExpandableAttentionCard"
import { MDetails } from "src/ui/ExpandableSection/MDetails"
import { spacing } from "src/ui/spacing"

export function MDetailsDemo() {
  const [openUndecorated, setOpenUndecorated] = useState(false)
  const [openAccordion, setOpenAccordion] = useState(false)

  return (
    <Grid>
      <MDetails
        title="MDetail uncontrolled"
        children={
          <MDetailsChildrenWrapper>
            This is an open uncontrolled MDetail card
          </MDetailsChildrenWrapper>
        }
      />

      <MDetails
        open={openUndecorated}
        onChange={() => setOpenUndecorated(!openUndecorated)}
        title={
          <div>
            <span>MDetail undecorated </span>
            <button
              onClick={() =>
                alert("Clicking me should not toggle expanded state")
              }
            >
              Click
            </button>
          </div>
        }
      >
        <MDetailsChildrenWrapper>
          This is an undecorated MDetail
        </MDetailsChildrenWrapper>
      </MDetails>

      <ExpandableAttentionCard
        title="MDetail: Warning card"
        type="warning"
        children="This is a WarningCard"
        storeState={true}
        id={"expandable-section-demo"}
      />

      <ExpandableAttentionCard
        title="MDetail: Emergency card"
        type="emergency"
        children="This is an EmergencyCard"
      />

      <MDetails
        open={openAccordion}
        onChange={() => setOpenAccordion(!openAccordion)}
        title={"MDetail Accordions card"}
        variant="card"
        summaryProps={{ bgColor: divider }}
        children={<MDetailAccordions />}
      />

      <NonLiveBanner />
    </Grid>
  )
}

function MDetailAccordions() {
  const [openAccordion, setOpenAccordion] = useState(false)
  const [openAccordion2, setOpenAccordion2] = useState(false)
  const [openAccordion3, setOpenAccordion3] = useState(false)

  return (
    <Grid2>
      <MDetails
        variant="accordion"
        open={openAccordion}
        onChange={() => setOpenAccordion(!openAccordion)}
        title={"MDetail accordion 1"}
      >
        Accordion1 contents
      </MDetails>
      <MDetails
        variant="accordion"
        open={openAccordion2}
        onChange={() => setOpenAccordion2(!openAccordion2)}
        title={"MDetail accordion 2"}
      >
        Accordion2 contents
      </MDetails>
      <MDetails
        variant="accordion"
        open={openAccordion3}
        onChange={() => setOpenAccordion3(!openAccordion3)}
        title={"MDetail accordion 3"}
      >
        Accordion3 contents
      </MDetails>
    </Grid2>
  )
}

const Grid = styled.div`
  display: grid;
  gap: 1rem;
`

const MDetailsChildrenWrapper = styled.div`
  padding-block: ${spacing.M};
`

const Grid2 = styled.div`
  display: grid;
  gap: 0.5rem;
  padding: ${spacing.M};
`
