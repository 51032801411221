import styled from "styled-components"

import { LinearProgressBar } from "src/components/ProgressBar/LinearProgressBar"
import { Divider } from "src/ui/Divider/Divider"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function LinearProgressBarDemo() {
  return (
    <Grid>
      <div>
        <LinearProgressBar
          segments={[
            { value: 30, type: "primary" },
            { value: 30, type: "good" },
            { value: 10, type: "info" },
            { value: 7, type: "warning" },
            { value: 3, type: "error" },
          ]}
        />
      </div>

      <Divider />

      <SizeGrid>
        <div>
          <MText variant="subtitle" marginBottom={spacing.XS}>
            Small
          </MText>
          <LinearProgressBar segments={[{ value: 70 }]} height="S" />
        </div>
        <div>
          <MText variant="subtitle" marginBottom={spacing.XS}>
            Medium (default)
          </MText>
          <LinearProgressBar segments={[{ value: 70 }]} height="M" />
        </div>
        <div>
          <MText variant="subtitle" marginBottom={spacing.XS}>
            Large
          </MText>
          <LinearProgressBar segments={[{ value: 70 }]} height="L" />
        </div>
      </SizeGrid>

      <Divider />

      <div>
        <MText variant="subtitle" marginBottom={spacing.XS}>
          Without rounded borders
        </MText>
        <LinearProgressBar
          segments={[{ value: 70, type: "good" }]}
          showRoundedBorders={false}
        />
      </div>

      <div>
        <MText variant="subtitle" marginBottom={spacing.XS}>
          Full with rounded borders
        </MText>
        <LinearProgressBar segments={[{ value: 100, type: "good" }]} />
      </div>
    </Grid>
  )
}

const Grid = styled.div`
  display: grid;
  gap: ${spacing.L};
`

const SizeGrid = styled.div`
  display: grid;
  gap: ${spacing.L};
`
