import { ReactElement } from "react-markdown"
import styled from "styled-components"

/**
 * A dumb component that groups multiple buttons together by
 * applying border-radius to the first and last button only.
 *
 * The caller is responsible for settings the correct variant and size
 * for each button.
 *
 * @example
 * ```tsx
 * <ButtonGroup>
 *   <MButton>First</MButton>
 *   <MButton>Second</MButton>
 * </ButtonGroup>
 * ```
 */
export function ParadiseButtonGroup({
  children,
}: {
  children: ReactElement[]
}) {
  return <Wrapper>{children}</Wrapper>
}

const Wrapper = styled.div`
  display: flex;

  & > button {
    position: relative;
    border-radius: 0;
    margin-left: -1px;
  }

  & > button:hover {
    z-index: 1;
  }

  & > button:first-child {
    border-radius: 9999px 0 0 9999px;
  }

  & > button:last-child {
    border-radius: 0 9999px 9999px 0;
  }
`
