import styled from "styled-components"

import {
  containerDemoVariables,
  Item,
} from "src/components/Sandbox/ContainerQueriesDemo/common"
import { spacing } from "src/ui/spacing"

export function LayoutShowCase() {
  return (
    <LayoutShowcaseBox>
      <div>Stacked</div>
      <div>Article</div>

      <StackedLayout>
        <StackedLayoutMainChild />
        <Item></Item>
        <Item></Item>
      </StackedLayout>

      <ArticleLayout>
        <ArticleLayoutMainChild />
        <Item></Item>
        <Item></Item>
      </ArticleLayout>
    </LayoutShowcaseBox>
  )
}

const LayoutShowcaseBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 0.5rem;
  padding: ${spacing.M};
`

const MainChild = styled(Item)`
  display: grid;
  place-content: center;
  &::before {
    content: ${containerDemoVariables.mainChildContent};
  }
`

const StackedLayout = styled.div`
  display: grid;
  gap: ${containerDemoVariables.narrowGap};
  padding: ${containerDemoVariables.narrowPadding};
  border: 1px solid black;
`
const StackedLayoutMainChild = styled(MainChild)`
  background-color: ${containerDemoVariables.narrowChildColor};
`
const ArticleLayout = styled.div`
  display: grid;
  gap: ${containerDemoVariables.wideGap};
  padding: ${containerDemoVariables.widePadding};
  grid-template-columns: ${containerDemoVariables.wideGridCols};
  border: 1px solid black;
`
const ArticleLayoutMainChild = styled(MainChild)`
  grid-row: ${containerDemoVariables.wideChildRows};
  background-color: ${containerDemoVariables.wideChildColor};
`
