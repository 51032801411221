import { useRef, useState } from "react"
import styled from "styled-components"

import { Placement } from "@floating-ui/react"

import { cardBoxShadow } from "src/constants/shadows"
import { MButton } from "src/ui/Button/MButton"
import { mColors } from "src/ui/colors"
import { MPopover } from "src/ui/MPopover/MPopover"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function MPopoverDemo() {
  const [config, setConfig] = useState<{
    triggerType: "hover" | "click"
    placement: Placement
    offset: {
      mainAxis?: number
      crossAxis?: number
      alignmentAxis?: number
    }
  }>({
    triggerType: "click",
    placement: "bottom",
    offset: {
      mainAxis: 10,
      crossAxis: 0,
      alignmentAxis: 0,
    },
  })

  const rootRef = useRef<HTMLDivElement>(null)

  return (
    <div>
      <MText variant="subtitleS">Trigger type</MText>
      <select
        value={config.triggerType}
        onChange={(e) =>
          setConfig((prev) => ({
            ...prev,
            triggerType: e.target.value as "hover" | "click",
          }))
        }
      >
        <option value="click">Click</option>
        <option value="hover">Hover</option>
      </select>

      <MText variant="subtitleS">Placement</MText>
      <select
        value={config.placement}
        onChange={(e) =>
          setConfig((prev) => ({
            ...prev,
            placement: e.target.value as Placement,
          }))
        }
      >
        <option value="top">Top</option>
        <option value="right">Right</option>
        <option value="bottom">Bottom</option>
        <option value="left">Left</option>
        <option value="top-start">Top-Start</option>
        <option value="top-end">Top-End</option>
        <option value="right-start">Right-Start</option>
        <option value="right-end">Right-End</option>
        <option value="bottom-start">Bottom-Start</option>
        <option value="bottom-end">Bottom-End</option>
        <option value="left-start">Left-Start</option>
        <option value="left-end">Left-End</option>
      </select>

      <MText variant="subtitleS">Offset (mainAxis)</MText>
      <input
        type="number"
        value={config.offset.mainAxis}
        onChange={(e) =>
          setConfig((prev) => ({
            ...prev,
            offset: {
              ...prev.offset,
              mainAxis: Number(e.target.value),
            },
          }))
        }
      />

      <MText variant="subtitleS">Offset (crossAxis)</MText>
      <input
        type="number"
        value={config.offset.crossAxis}
        onChange={(e) =>
          setConfig((prev) => ({
            ...prev,
            offset: {
              ...prev.offset,
              crossAxis: Number(e.target.value),
            },
          }))
        }
      />

      <MText variant="subtitleS">Offset (alignmentAxis)</MText>
      <input
        type="number"
        value={config.offset.alignmentAxis}
        onChange={(e) =>
          setConfig((prev) => ({
            ...prev,
            offset: {
              ...prev.offset,
              alignmentAxis: Number(e.target.value),
            },
          }))
        }
      />

      <MPopover
        trigger={<MButton>Interact with me</MButton>}
        root={rootRef}
        triggerType={config.triggerType}
        placement={config.placement}
        offset={config.offset}
        inline
        disableScroll
      >
        <PopoverContent>
          <MText variant="heading3">Hello there</MText>
          <MButton variant="minimal">Button</MButton>
        </PopoverContent>
      </MPopover>
    </div>
  )
}

const PopoverContent = styled.div`
  background-color: ${mColors.neutral};
  padding: ${spacing.S};
  border-radius: 1rem;
  ${cardBoxShadow}
`
