import { useState } from "react"

import { TimeSlider } from "src/components/Settings/EditField/Slider"

import {
  ISettingContainerBaseProps,
  SettingContainer,
  TSettingContainerOnSaveReturnType,
} from "./SettingContainer"

export function SettingSlider({
  title,
  description,
  value,
  min,
  max,
  unit,
  onSave,
  ...props
}: ISettingContainerBaseProps & {
  onSave(newVal: number): TSettingContainerOnSaveReturnType
  value: number
  min: number // make optional
  max: number // make optional
  unit: string
}) {
  const [saveValue, setSaveValue] = useState(value)

  return (
    <SettingContainer
      title={title}
      description={description}
      onSave={() => {
        return onSave(saveValue)
      }}
      displayValue={`${String(value)} ${unit}`}
      InputComponent={() => (
        <TimeSlider
          value={value}
          min={min}
          max={max}
          unit={unit}
          setValue={setSaveValue}
          warning={""}
        />
      )}
      {...props}
    />
  )
}
