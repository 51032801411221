import { useState } from "react"

import { VerticalWizard } from "src/ui/VerticalWizard/VerticalWizard"

const msg =
  "Fusce maximus sapien eros, sed porta lacus efficitur vel. Sed ullamcorper iaculis tellus id mollis. Donec tincidunt condimentum nisl vitae mattis. Ut dignissim condimentum posuere. Mauris vestibulum sed sem vel aliquam. Donec porta rhoncus dignissim. Morbi porta felis ut porta tristique. Vivamus dapibus id risus vel feugiat. Ut suscipit tortor nulla, sit amet congue ex pulvinar congue. Proin eu tempus purus. Aliquam nec ornare massa."

export function VerticalWizardDemo() {
  const [currentStep, setCurrentStep] = useState(0)

  const steps = [
    {
      title: "Choose plan",
      component: <div>{msg}</div>,
      preview: "Pro",
      completed: true,
    },
    {
      title: "Payment method",
      component: <div>{msg}</div>,
      completed: false,
    },
    {
      title: "Review & confirm changes",
      component: <div>{msg}</div>,
      preview: "$1000000",
      completed: true,
    },
  ]

  return (
    <article>
      <VerticalWizard
        steps={steps}
        currentStep={currentStep}
        onEdit={(stepIndex: number) => {
          setCurrentStep(stepIndex)
        }}
        onNext={() => {
          setCurrentStep((s) => s + 1)
        }}
      />
    </article>
  )
}
