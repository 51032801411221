import { useState } from "react"

import { MCircularProgress } from "src/ui/MCircularProgress/MCircularProgress"
import { spacing } from "src/ui/spacing"

export function MCircularProgressDemo() {
  const [size, setSize] = useState(24)

  return (
    <div style={{ display: "grid", gap: spacing.M }}>
      <input
        type="number"
        value={size}
        onChange={(e) => setSize(Number(e.target.value))}
      />
      <MCircularProgress size={size} />
    </div>
  )
}
