import { useState } from "react"

import { ChangePlanBreakDownDialog } from "src/components/Account/BillingPortal/ChangePlan/Breakdown/ChangePlanBreakDownDialog"
import { TChangePlanBreakdownData } from "src/components/Account/BillingPortal/ChangePlan/Breakdown/changePlanBreakdownTypes"

const data: TChangePlanBreakdownData = {
  billedNow: "$285.51",
  vat: "24%",
  items: [
    {
      state: "activated",
      quantity: 3,
      days_until_renewal: 214,
      prorated_unit_amount: 3517,
      amount: 10551,
      currency_code: "USD",
    },
    {
      state: "prepaid",
      quantity: 3,
      days_until_renewal: 365,
      prorated_unit_amount: 6000,
      amount: 18000,
      currency_code: "USD",
    },
  ],
}

export function ChangePlanBreakdownDemo() {
  const [open, setOpen] = useState(false)

  return (
    <div>
      <button onClick={() => setOpen(true)}>
        Open change plan breakdown dialog
      </button>

      <ChangePlanBreakDownDialog
        data={data}
        open={open}
        onClose={() => setOpen(false)}
      />
    </div>
  )
}
