import { useState } from "react"

import { InlineWizard } from "src/ui/Wizard/InlineWizard"
import { IWizardStep } from "src/ui/Wizard/wizardTypes"

export function InlineWizardDemo() {
  const [currentStep, setCurrentStep] = useState(0)
  const steps: IWizardStep[] = [
    {
      component: <div>Inline wizard step 1</div>,
      border: false,
      hideBackButton: true,
      onNext: () => {
        setCurrentStep((currStep) => currStep + 1)
      },
    },
    {
      component: <div>Inline wizard step 2</div>,
      border: false,
      hideNextButton: true,
      onBack: () => {
        setCurrentStep((currStep) => currStep - 1)
      },
    },
  ]
  return <InlineWizard currentStep={currentStep} steps={steps} />
}
