import { JsonTree } from "src/ui/JsonTree/JsonTree"

export function JsonTreeDemo() {
  return (
    <JsonTree
      json={{
        jsonPrimitives: {
          stringValue: "foobar",
          numberValue: 42,
          falseValue: false,
          trueValue: true,
          nullValue: null,
          undefinedValue: undefined,
        },
        person: {
          name: "John Doe",
          age: 30,
          address: {
            street: "123 Main St",
            city: "Anytown",
            country: "USA",
          },
          contacts: [
            {
              type: "email",
              value: "john.doe@example.com",
            },
            {
              type: "phone",
              value: "+1 555-1234",
            },
          ],
          friends: [
            {
              name: "Alice",
              age: 28,
              interests: ["reading", "traveling"],
              address: {
                city: "Wonderland",
              },
            },
            {
              name: "Bob",
              age: 32,
              interests: ["coding", "gaming"],
              address: {
                city: "Techtopia",
                coordinates: {
                  latitude: 40.7128,
                  longitude: -74.006,
                },
              },
            },
          ],
        },
        organization: {
          name: "TechCorp",
          employees: [
            {
              name: "Emily",
              department: "HR",
              address: {
                city: "Businessville",
              },
            },
            {
              name: "Charlie",
              department: "Engineering",
              address: {
                city: "CodeCity",
                coordinates: {
                  latitude: 37.7749,
                  longitude: -122.4194,
                },
              },
            },
          ],
        },
      }}
    />
  )
}
