import { useState } from "react"
import styled from "styled-components"

import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { DropdownMultiSelect } from "src/ui/DropdownSelect/DropdownMultiSelect"
import { TSelectOption } from "src/ui/DropdownSelect/DropdownSelect"
import { MCardInteractive } from "src/ui/MCard/MCardInteractive"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function DropdownMultiSelectDemo() {
  const { _t } = useTranslate()
  const translatedLabels = eventGroups.map((e) => ({
    ...e,
    label: _t(e.label),
  }))
  const [groupValues, setGroupValues] = useState<TEventGroupValues[]>([])
  const [options, setOptions] = useState(translatedLabels)

  function handleChange({
    checked,
    option,
  }: {
    checked: boolean
    option: TSelectOption<TEventGroupValues>
  }) {
    if (checked) {
      setGroupValues((prev) => {
        return Array.from(new Set([...prev, option.value]))
      })
    } else {
      setGroupValues((prev) => prev.filter((o) => o !== option.value))
    }
  }

  function handleSearch(searchValue: string) {
    setOptions(() =>
      translatedLabels.filter((option) =>
        option.label
          .toLocaleLowerCase()
          .includes(searchValue.toLocaleLowerCase())
      )
    )
  }

  function handleClearSelection() {
    setGroupValues([])
  }

  return (
    <div>
      <DropdownMultiSelect
        options={options}
        onSearch={handleSearch}
        selectedValues={groupValues}
        onChange={handleChange}
        placeholder="Search..."
        label="Event type"
        onClearSelection={handleClearSelection}
      />
      <StyledCard>
        <MText variant="subtitle">Selected values: </MText>
        <div>{groupValues.map((v) => `${v}, `)}</div>
      </StyledCard>
    </div>
  )
}

const StyledCard = styled(MCardInteractive)`
  margin-top: ${spacing.L};
`

type TEventGroupValues =
  | "security_alarm"
  | "alarm_recognition"
  | "out_of_battery"
  | "low_battery"
  | "sensor_installed"
  | "sensor_offline"
  | "sensor_online"
  | "sensor_turned_off"
  | "responder_sent"
  | "noise_issue_solved"
  | "contacts_alerted"
  | "first_noise_alert"
  | "second_noise_alert"
  | "third_noise_alert"
  | "window_break"
  | "low_humidity"
  | "high_humidity"
  | "crowd_detect"
  | "response_partners_callout"
  | "mold_risk"
  | "sensor_swap"
  | "button_pressed"
  | "sensor_attached"
  | "sensor_removed"
  | "low_temperature"
  | "high_temperature"
  | "smoking_detection_smoking_detected"

const eventGroups: TSelectOption<TEventGroupValues>[] = [
  {
    label: langKeys.event_filter_security_alarm_label,
    value: "security_alarm",
  },
  {
    label: langKeys.event_filter_alarm_recognition_label,
    value: "alarm_recognition",
  },
  {
    label: langKeys.event_filter_out_of_battery_label,
    value: "out_of_battery",
  },
  {
    label: langKeys.event_filter_low_battery_label,
    value: "low_battery",
  },
  {
    label: langKeys.event_filter_sensor_installed_label,
    value: "sensor_installed",
  },
  {
    label: langKeys.event_filter_sensor_offline_label,
    value: "sensor_offline",
  },
  {
    label: langKeys.event_filter_sensor_online_label,
    value: "sensor_online",
  },
  {
    label: langKeys.event_filter_sensor_turned_off_label,
    value: "sensor_turned_off",
  },
  {
    label: langKeys.event_filter_responder_sent_label,
    value: "responder_sent",
  },
  {
    label: langKeys.event_filter_noise_issue_solved_label,
    value: "noise_issue_solved",
  },
  {
    label: langKeys.event_filter_contacts_alerted_label,
    value: "contacts_alerted",
  },
  {
    label: langKeys.event_filter_first_noise_alert_label,
    value: "first_noise_alert",
  },
  {
    label: langKeys.event_filter_second_noise_alert_label,
    value: "second_noise_alert",
  },
  {
    label: langKeys.event_filter_third_noise_alert_label,
    value: "third_noise_alert",
  },
  {
    label: langKeys.event_filter_window_break_label,
    value: "window_break",
  },
  {
    label: langKeys.event_filter_low_humidity_label,
    value: "low_humidity",
  },
  {
    label: langKeys.event_filter_high_humidity_label,
    value: "high_humidity",
  },
  {
    label: langKeys.event_filter_crowd_detect_label,
    value: "crowd_detect",
  },
  {
    label: langKeys.event_filter_response_partners_callout_label,
    value: "response_partners_callout",
  },
  {
    label: langKeys.event_filter_mold_risk_label,
    value: "mold_risk",
  },
  {
    label: langKeys.event_filter_sensor_swap_label,
    value: "sensor_swap",
  },
  {
    label: langKeys.event_filter_button_pressed_label,
    value: "button_pressed",
  },
  {
    label: langKeys.event_filter_sensor_attached_label,
    value: "sensor_attached",
  },
  {
    label: langKeys.event_filter_sensor_removed_label,
    value: "sensor_removed",
  },
  {
    label: langKeys.event_filter_low_temperature_label,
    value: "low_temperature",
  },
  {
    label: langKeys.event_filter_high_temperature_label,
    value: "high_temperature",
  },
  {
    label: langKeys.smoking_detection_smoking_detected_title,
    value: "smoking_detection_smoking_detected",
  },
]
