import styled from "styled-components"

import { Avatar } from "@material-ui/core"

import { Hoverable } from "src/ui/Hoverable/Hoverable"
import { InternalLink } from "src/ui/Link/InternalLink"
import { spacing } from "src/ui/spacing"

export function HoverableDemo() {
  return (
    <Hoverable
      placement="bottom-start"
      trigger={<InternalLink to="#">Hover over me</InternalLink>}
      content={
        <HoverableWrapper>
          <Wrapper>
            <Avatar />
            <div>
              <b>Jane Doe</b>
              <div>jane.doe@gmail.com</div>
            </div>
          </Wrapper>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus
            itaque accusantium eum.
          </p>
        </HoverableWrapper>
      }
    />
  )
}

const HoverableWrapper = styled.div`
  padding: ${spacing.M};
`

const Wrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`
