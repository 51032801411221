import styled from "styled-components"

import { BaseModalDialogDemo } from "src/components/Sandbox/BaseModalDialogDemo"
import { BreadcrumbDemo } from "src/components/Sandbox/BreadcrumbDemo"
import { ChangePlanBreakdownDemo } from "src/components/Sandbox/ChangePlanBreakdownDemo"
import { ChatDemo } from "src/components/Sandbox/ChatDemo"
import { ComboboxDemo } from "src/components/Sandbox/ComboboxDemo/ComboboxDemo"
import { ContainerQueriesDemo } from "src/components/Sandbox/ContainerQueriesDemo/ContainerQueriesDemo"
import { NotificationsDemo } from "src/components/Sandbox/DesktopNotifications/NotificationsDemo"
import { DropdownMultiSelectDemo } from "src/components/Sandbox/DropdownMultiSelectDemo"
import { DropdownSelectDemo } from "src/components/Sandbox/DropdownSelectDemo"
import { FilterDemo } from "src/components/Sandbox/FilterDemo/FilterDemo"
import { InlineWizardDemo } from "src/components/Sandbox/InlineWizardDemo"
import { JsonTreeDemo } from "src/components/Sandbox/JsonTreeDemo"
import { LinearProgressBarDemo } from "src/components/Sandbox/LinearProgressBarDemo"
import { MBadgeDemo } from "src/components/Sandbox/MBadgeDemo"
import { MBannerDemo } from "src/components/Sandbox/MBannerDemo"
import { MButtonDemo } from "src/components/Sandbox/MButtonDemo"
import { MCheckboxDemo } from "src/components/Sandbox/MCheckboxDemo"
import { MCircularProgressDemo } from "src/components/Sandbox/MCircularProgressDemo"
import { MDetailsDemo } from "src/components/Sandbox/MDetailsDemo"
import { MDialogDemo } from "src/components/Sandbox/MDialogDemo"
import { MinutQueryDemo } from "src/components/Sandbox/MinutQueryDemo"
import { MLinkDemo } from "src/components/Sandbox/MLinkDemo"
import { MNumberInputDemo } from "src/components/Sandbox/MNumberInputDemo"
import { MPopoverDemo } from "src/components/Sandbox/MPopoverDemo"
import { MSelectDemo } from "src/components/Sandbox/MSelectDemo"
import { MSkeletonDemo } from "src/components/Sandbox/MSkeletonDemo"
import { MTextAreaDemo } from "src/components/Sandbox/MTextAreaDemo"
import { MTextDemo } from "src/components/Sandbox/MTextDemo"
import { MTextFieldDemo } from "src/components/Sandbox/MTextFieldDemo"
import { MTooltipDemo } from "src/components/Sandbox/MTooltipDemo"
import { ParadiseButtonGroupDemo } from "src/components/Sandbox/ParadiseButtonGroupDemo"
import { SearchFilterDemo } from "src/components/Sandbox/SearchFilterDemo"
import { SettingsDemo } from "src/components/Sandbox/SettingsDemo"
import { VerticalWizardDemo } from "src/components/Sandbox/VerticalWizardDemo"
import { WebSerialDemo } from "src/components/Sandbox/WebSerialDemo"
import { ExpandableSection } from "src/ui/ExpandableSection/ExpandableSection"
import { MainView } from "src/ui/Layout/MainView"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MLink } from "src/ui/Link/MLink"
import { MCard } from "src/ui/MCard/MCard"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { slugify } from "src/utils/genericUtil"

import { DateTimeDemo } from "./DateTimeDemo"
import { HoverableDemo } from "./HoverableDemo"

const sandboxItems: IItem[] = [
  {
    title: "minutQuery",
    component: <MinutQueryDemo />,
  },
  {
    title: "Date & Time",
    component: <DateTimeDemo />,
  },
  {
    title: "Link",
    component: <MLinkDemo />,
  },
  {
    title: "Notifications",
    component: <NotificationsDemo />,
  },
  {
    title: "MText",
    component: <MTextDemo />,
  },
  {
    title: "MButton",
    component: <MButtonDemo />,
  },
  {
    title: "MBanner",
    component: <MBannerDemo />,
  },
  {
    title: "MBadge",
    component: <MBadgeDemo />,
  },
  {
    title: "MDetail",
    component: <MDetailsDemo />,
  },
  {
    title: "Breadcrumbs",
    component: <BreadcrumbDemo />,
  },
  {
    title: "Webserial connect",
    component: <WebSerialDemo />,
  },
  {
    title: "Websocket chat",
    component: <ChatDemo showSmoothScrollToggle />,
  },
  {
    title: "Dropdown multi-select",
    component: <DropdownMultiSelectDemo />,
  },
  {
    title: "MCard",
    component: (
      <MCard>
        <p>A generic MCard component</p>
      </MCard>
    ),
  },
  {
    title: "Linear progress bar",
    component: <LinearProgressBarDemo />,
  },
  {
    title: "Settings",
    component: <SettingsDemo />,
  },
  {
    title: "VerticalWizard",
    component: <VerticalWizardDemo />,
  },
  {
    title: "NumberInput",
    component: <MNumberInputDemo />,
  },
  {
    title: "InlineWizard",
    component: <InlineWizardDemo />,
  },
  {
    title: "Change plan breakdown",
    component: <ChangePlanBreakdownDemo />,
  },
  {
    title: "ImageBackdrop",
    component: (
      <div>
        <InternalLink to="/sandbox/backdrop">Go to Image backdrop</InternalLink>
      </div>
    ),
  },
  {
    title: "Container queries",
    component: <ContainerQueriesDemo />,
  },
  {
    title: "Hoverable",
    component: <HoverableDemo />,
  },
  {
    title: "JsonTree",
    component: <JsonTreeDemo />,
  },
  {
    title: "Filter",
    component: <FilterDemo />,
  },
  {
    title: "MTextField",
    component: <MTextFieldDemo />,
  },
  {
    title: "MSelectDemo",
    component: <MSelectDemo />,
  },
  {
    title: "Combobox",
    component: <ComboboxDemo />,
  },
  {
    title: "DropdownSelectDemo",
    component: <DropdownSelectDemo />,
  },
  {
    title: "MTextAreaDemo",
    component: <MTextAreaDemo />,
  },
  {
    title: "SearchFilterDemo",
    component: <SearchFilterDemo />,
  },
  {
    title: "BaseModalDialog",
    component: <BaseModalDialogDemo />,
  },
  {
    title: "MSkeletonDemo",
    component: <MSkeletonDemo />,
  },
  {
    title: "MTooltip",
    component: <MTooltipDemo />,
  },
  {
    title: "MPopover",
    component: <MPopoverDemo />,
  },
  {
    title: "MDialogDemo",
    component: <MDialogDemo />,
  },
  {
    title: "ParadiseButtonGroup",
    component: <ParadiseButtonGroupDemo />,
  },
  {
    title: "MCircularProgress",
    component: <MCircularProgressDemo />,
  },
  {
    title: "MCheckbox",
    component: <MCheckboxDemo />,
  },
]

const sandboxItemsWithId: IItemWithId[] = sandboxItems.map((i) => ({
  ...i,
  id: slugify(i.title),
}))

export function Sandbox() {
  return (
    <MainView title="Sandbox" size="small">
      <ToC>
        {sandboxItemsWithId.map(({ title, id }) => {
          return (
            <MLink href={`#${id}`} key={id}>
              {title}
            </MLink>
          )
        })}
      </ToC>
      <Grid>
        {sandboxItemsWithId.map(({ title, component, id }) => {
          return (
            <Item key={id} id={id} title={title}>
              {component}
            </Item>
          )
        })}
      </Grid>
    </MainView>
  )
}

function Item({
  title,
  id,
  children,
}: {
  title: string
  id: string
  children: React.ReactNode
}) {
  return (
    <ExpandableSection
      id={id}
      title={<MText variant="heading2">{title}</MText>}
      storeState
    >
      <Box>{children}</Box>
    </ExpandableSection>
  )
}

const Box = styled.div`
  padding-block: ${spacing.M};
`

const Grid = styled.div`
  display: grid;
  gap: ${spacing.XL3};
`

const ToC = styled.div`
  margin-bottom: 2rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`

interface IItem {
  title: string
  component: React.ReactNode
}
interface IItemWithId extends IItem {
  id: string
}
