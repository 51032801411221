import { useState } from "react"
import styled from "styled-components"

import { useFetchParadiseUsers } from "src/data/paradise/paradiseUsers/queries/paradiseUserQueries"
import { colorsLegacy, greyScale, mColors } from "src/ui/colors"
import SearchIcon from "src/ui/icons/search.svg"
import { MCheckbox } from "src/ui/MCheckbox/MCheckbox"
import { MCircularProgress } from "src/ui/MCircularProgress/MCircularProgress"
import { MText } from "src/ui/MText"
import { MTextField } from "src/ui/MTextField/MTextField"
import { spacing } from "src/ui/spacing"

export function UsersFilter({
  selectedValues,
  onChange,
}: {
  selectedValues: {
    value: string
    label: string
  }[]
  onChange: (
    selected: {
      value: string
      label: string
    }[]
  ) => void
}) {
  const [searchInput, setSearchInput] = useState("")

  const fetchUsers = useFetchParadiseUsers({
    filter: {
      email: searchInput,
    },
  })

  return (
    <div>
      <StyledSearch>
        <MTextField
          value={searchInput}
          onChange={(value) => setSearchInput(value)}
          endAdornment={
            fetchUsers.isLoading ? (
              <MCircularProgress size={20} />
            ) : (
              <SearchIcon color={mColors.textInactive} width={18} />
            )
          }
          size="small"
        />
      </StyledSearch>

      {fetchUsers.data?.users.length === 0 && searchInput.length > 0 && (
        <EmptyBox>
          <MText variant="nano">Not found</MText>
        </EmptyBox>
      )}

      <ItemsList>
        {fetchUsers.data?.users.map((user) => (
          <div key={user.user_id}>
            <StyledMCheckbox
              label={<StyledNanoText>{user.email}</StyledNanoText>}
              checked={
                selectedValues.findIndex((v) => v.value === user.user_id) > -1
              }
              onCheck={() => {
                if (
                  selectedValues.findIndex((v) => v.value === user.user_id) ===
                  -1
                ) {
                  onChange([
                    ...selectedValues,
                    { label: user.email, value: user.user_id },
                  ])
                } else {
                  onChange(
                    selectedValues.filter((s) => s.value !== user.user_id)
                  )
                }
              }}
              fullWidth
            />
          </div>
        ))}
      </ItemsList>
    </div>
  )
}

const StyledSearch = styled.div`
  padding: 0 ${spacing.S} ${spacing.S} ${spacing.S};
`

const EmptyBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${spacing.XL};
  color: ${colorsLegacy.minimumGray};
`

const ItemsList = styled.div`
  max-height: 300px;
  overflow: auto;
  border-top: 1px solid ${greyScale[50]};
  border-bottom: 1px solid ${greyScale[50]};
`

const StyledMCheckbox = styled(MCheckbox)`
  padding: ${spacing.M} ${spacing.S} ${spacing.M} ${spacing.M};

  &:hover {
    background-color: ${mColors.neutral};
  }
`

const StyledNanoText = styled(MText)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`
