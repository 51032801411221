import { useRef, useState } from "react"
import styled from "styled-components"

import { Placement } from "@floating-ui/react"

import { MText } from "src/ui/MText"
import { MTooltip } from "src/ui/MTooltip/MTooltip"

export function MTooltipDemo() {
  const [placement, setPlacement] = useState<Placement>("bottom")
  const playgroundRef = useRef<HTMLDivElement>(null)

  return (
    <div>
      <MText>Select placement</MText>
      <select
        value={placement}
        onChange={(e) => setPlacement(e.target.value as Placement)}
      >
        <option value="top">Top</option>
        <option value="right">Right</option>
        <option value="bottom">Bottom</option>
        <option value="left">Left</option>
        <option value="top-start">Top-Start</option>
        <option value="top-end">Top-End</option>
        <option value="right-start">Right-Start</option>
        <option value="right-end">Right-End</option>
        <option value="bottom-start">Bottom-Start</option>
        <option value="bottom-end">Bottom-End</option>
        <option value="left-start">Left-Start</option>
        <option value="left-end">Left-End</option>
      </select>

      <Playground ref={playgroundRef}>
        <MTooltip
          title="Some content"
          placement={placement}
          root={playgroundRef}
        >
          Hover me
        </MTooltip>
      </Playground>
    </div>
  )
}

const Playground = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  resize: both;
  overflow: auto;

  width: 100%;
  height: 200px;
  border: 1px solid;
`
