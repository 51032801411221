import { useState } from "react"
import styled from "styled-components"

import { SearchFilter } from "src/components/Filter/SearchFilter"
import { spacing } from "src/ui/spacing"

export function SearchFilterDemo() {
  const [filterPlaceholder, setFilterPlaceholder] = useState("")
  const [disabled, setDisabled] = useState(false)

  return (
    <Container>
      <label>
        Placeholder
        <input
          value={filterPlaceholder}
          onChange={(e) => setFilterPlaceholder(e.target.value)}
        />
      </label>
      <label>
        <input
          type="checkbox"
          checked={disabled}
          onChange={(e) => setDisabled(e.target.checked)}
        />
        Disabled
      </label>
      <SearchFilter
        onChange={() => {}}
        placeholder={filterPlaceholder}
        disabled={disabled}
      />
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  gap: ${spacing.L};
`
