import { useMemo, useState } from "react"

import { useDebounceValue } from "usehooks-ts"

import { Combobox } from "src/ui/Combobox/Combobox"

const options = [
  {
    label: "Red",
    value: "red",
  },
  {
    label: "Blue",
    value: "blue",
  },
  {
    label: "Green",
    value: "green",
  },
  {
    label: "Yellow",
    value: "yellow",
  },
  {
    label: "Purple",
    value: "purple",
  },
  {
    label: "Orange",
    value: "orange",
  },
  {
    label: "Black",
    value: "black",
  },
  {
    label: "White",
    value: "white",
  },
  {
    label: "Pink",
    value: "pink",
  },
  {
    label: "Brown",
    value: "brown",
  },
]

export function ComboboxDebounceDemo() {
  const [searchedInput, setSearchedInput] = useState("")
  const [debouncedInput, setDebouncedInput] = useDebounceValue("", 500)

  const [selected, setSelected] = useState("red")

  const filteredOptions = useMemo(() => {
    return options.filter((option) =>
      option.label.toLowerCase().includes(debouncedInput.toLowerCase())
    )
  }, [debouncedInput])

  return (
    <div style={{ display: "grid", gap: "1rem" }}>
      <label>Debounced input: {debouncedInput}</label>
      <Combobox
        label="Default"
        options={filteredOptions}
        selectedValue={selected}
        onChange={(value) => {
          setSelected(value)
        }}
        input={searchedInput}
        onInput={(input) => {
          setSearchedInput(input)
          setDebouncedInput(input)
        }}
      />
    </div>
  )
}
