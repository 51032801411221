import styled from "styled-components"

import { spacing } from "src/ui/spacing"

export const Item = styled.div`
  height: 100%;
  min-height: 20px;
  min-width: 20px;
  background: gray;
`

export const containerDemoVariables = {
  narrowChildColor: "yellow",
  narrowGap: spacing.XS,
  narrowPadding: spacing.XS,

  wideChildColor: "green",
  wideGap: spacing.M,
  widePadding: spacing.M,
  wideChildRows: "1 / 3",
  wideGridCols: "1fr 1fr",

  mainChildContent: '"Main Child"',
}
