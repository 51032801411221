import { useState } from "react"

import { MCheckbox } from "src/ui/MCheckbox/MCheckbox"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function MCheckboxDemo() {
  const [error, setError] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [label, setLabel] = useState("false")
  const [checked, setChecked] = useState(false)

  return (
    <div>
      <label>
        <input
          type="checkbox"
          checked={error}
          onChange={(e) => setError(e.target.checked)}
        />
        Has error
      </label>
      <br />
      <label>
        <input
          type="checkbox"
          checked={disabled}
          onChange={(e) => setDisabled(e.target.checked)}
        />
        Is disabled
      </label>
      <br />
      <input
        placeholder="Label"
        value={label}
        onChange={(e) => setLabel(e.target.value)}
      />

      <MText variant="subtitle" marginBottom={spacing.XS}>
        Example
      </MText>
      <MCheckbox
        checked={checked}
        onCheck={(checked) => setChecked(checked)}
        label={label}
        error={error}
        disabled={disabled}
      />
    </div>
  )
}
