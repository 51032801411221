import { useMemo, useState } from "react"

import { Divider } from "src/ui/Divider/Divider"
import {
  DropdownSelect,
  TSelectOption,
} from "src/ui/DropdownSelect/DropdownSelect"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

const colorOptions = [
  {
    label: "Red",
    value: "red",
  },
  {
    label: "Blue",
    value: "blue",
  },
  {
    label: "Green",
    value: "green",
  },
  {
    label: "Yellow",
    value: "yellow",
  },
  {
    label: "Purple",
    value: "purple",
  },
  {
    label: "Orange",
    value: "orange",
  },
  {
    label: "Black",
    value: "black",
  },
  {
    label: "White",
    value: "white",
  },
  {
    label: "Pink",
    value: "pink",
  },
  {
    label: "Brown",
    value: "brown",
  },
]

export function DropdownSelectDemo() {
  const [searchText, setSearchText] = useState("")

  const [selectedOption, setSelectedOption] = useState<TSelectOption<string>>()

  const filteredOptions = useMemo(() => {
    return colorOptions.filter((option) =>
      option.label.toLowerCase().includes(searchText.toLowerCase())
    )
  }, [searchText])

  return (
    <div>
      <MText variant="heading3" marginBottom={spacing.M}>
        Default
      </MText>
      <DropdownSelect
        label={selectedOption?.label || "Select color"}
        options={filteredOptions}
        selectedValue={selectedOption?.value || ""}
        onChange={({ option }) => setSelectedOption(option)}
        searchText={searchText}
        onSearch={setSearchText}
        placeholder="Search"
        onClearSelection={() => {
          setSelectedOption(undefined)
          setSearchText("")
        }}
      />

      <Divider $margin={spacing.XL} />

      <MText variant="heading3" marginBottom={spacing.M}>
        Minimal
      </MText>

      <DropdownSelect
        label={selectedOption?.label || "Select color"}
        options={filteredOptions}
        selectedValue={selectedOption?.value || ""}
        onChange={({ option }) => setSelectedOption(option)}
        searchText={searchText}
        onSearch={setSearchText}
        mButtonVariant="minimal"
        mTextVariant="body"
        placeholder="Search"
        size="medium"
      />
    </div>
  )
}
