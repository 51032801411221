import { useState } from "react"

import { ComboboxDemo } from "src/components/Sandbox/ComboboxDemo/ComboboxDemo"
import { DateTimeDemo } from "src/components/Sandbox/DateTimeDemo"
import { DropdownMultiSelectDemo } from "src/components/Sandbox/DropdownMultiSelectDemo"
import { DropdownSelectDemo } from "src/components/Sandbox/DropdownSelectDemo"
import { MSelectDemo } from "src/components/Sandbox/MSelectDemo"
import { MDialog } from "src/ui/Dialog/MDialog"
import { ExpandableSection } from "src/ui/ExpandableSection/ExpandableSection"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function MDialogDemo() {
  return (
    <div>
      <MText variant="heading3" marginBottom={spacing.M}>
        Simple with no confirmation
      </MText>
      <MDialogNoConfirmDemo />
      <br />
      <MText variant="heading3" marginBottom={spacing.M}>
        Simple with no close button
      </MText>
      <MDialogNoCloseDemo />
      <br />
      <MText variant="heading3" marginBottom={spacing.M}>
        Regular
      </MText>
      <MDialogRegularDemo />
      <br />
      <MText variant="heading3" marginBottom={spacing.M}>
        When content is too long
      </MText>
      <MDialogOverflowDemo />
      <br />
      <MText variant="heading3" marginBottom={spacing.M}>
        Nested dialogs
      </MText>
      <MDialogNestedDemo />
    </div>
  )
}

function MDialogNoConfirmDemo() {
  const [open, setOpen] = useState(false)

  return (
    <div>
      <button onClick={() => setOpen(true)}>Open dialog</button>
      <MDialog title="Simple dialog" open={open} onClose={() => setOpen(false)}>
        Some content, without the need for confirmation
      </MDialog>
    </div>
  )
}

function MDialogNoCloseDemo() {
  const [open, setOpen] = useState(false)

  return (
    <div>
      <button onClick={() => setOpen(true)}>Open dialog</button>
      <MDialog
        title="Simple dialog"
        open={open}
        hideClose
        onConfirm={() => setOpen(false)}
        onClose={() => {
          setOpen(false)
        }}
      >
        Some content, without the need for close
      </MDialog>
    </div>
  )
}

function MDialogRegularDemo() {
  const [open, setOpen] = useState(false)

  return (
    <div>
      <button onClick={() => setOpen(true)}>Open dialog</button>
      <MDialog
        title="The regular demo"
        description="Here are some different demos inside the demo"
        open={open}
        onClose={() => setOpen(false)}
        onConfirm={() => {
          alert("confirmed")
        }}
      >
        <MText>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore,
          asperiores! Maiores architecto exercitationem doloribus quae, cumque
          at assumenda optio? Placeat dolorem aperiam voluptatem aliquid
          quibusdam impedit odit culpa quos maxime.
        </MText>
        <br />
        <ExpandableSection title="Combobox">
          <ComboboxDemo />
        </ExpandableSection>
        <br />
        <ExpandableSection title="MSelect">
          <MSelectDemo />
        </ExpandableSection>
        <br />
        <ExpandableSection title="DateTime">
          <DateTimeDemo />
        </ExpandableSection>
        <br />
        <ExpandableSection title="DropdownSelect">
          <DropdownSelectDemo />
        </ExpandableSection>
        <br />
        <ExpandableSection title="Dropdown multi-select">
          <DropdownMultiSelectDemo />
        </ExpandableSection>
      </MDialog>
    </div>
  )
}

function MDialogOverflowDemo() {
  const [open, setOpen] = useState(false)

  return (
    <div>
      <button onClick={() => setOpen(true)}>Open dialog</button>
      <MDialog
        title="This is the title"
        description="This is the description"
        open={open}
        onClose={() => setOpen(false)}
        onConfirm={() => {}}
        error="This is an error"
        infoAlert="This is some info"
      >
        <MText>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus
          sapiente, totam ducimus modi cupiditate, nam corrupti, ipsam eius
          possimus neque in sint ea repudiandae labore non. Consectetur porro
          nostrum eum. Facere quos error molestiae! Nemo quis vitae labore!
          Eligendi modi quisquam quae ipsam. Ipsum, dolorem deleniti? Harum
          officia nobis necessitatibus doloremque quos praesentium assumenda
          maiores obcaecati, facere impedit modi nihil? Quaerat est ipsum
          consequuntur quos inventore et beatae voluptatibus. Maiores earum
          delectus eaque sed voluptatum eius quae facere distinctio, hic in
          cumque, nobis quod facilis molestiae ratione eos quos! Porro.
          Explicabo voluptate modi doloribus impedit placeat, voluptates facere
          aspernatur dicta repellat perspiciatis alias fugiat repellendus minima
          atque aut eius dolorum earum reiciendis delectus! Adipisci fugit
          cupiditate eaque eos ad iste. Accusantium mollitia assumenda optio,
          tempora facilis dolor, amet adipisci nesciunt explicabo ullam
          veritatis ea perferendis dicta reiciendis eos molestias ipsam suscipit
          sint. Rerum, ex quae autem mollitia commodi aliquam. Earum? Fuga
          deserunt est labore tempora temporibus quam, mollitia, quas itaque,
          praesentium quia optio nulla sint consectetur porro laudantium libero
          molestias repellendus delectus quasi? Officia odit quibusdam iure quod
          natus ex. Pariatur natus praesentium nobis quod laborum amet nisi
          veniam maxime a cumque quaerat esse facilis molestiae hic expedita
          commodi mollitia, culpa, omnis quis! Iste dicta animi illum eligendi
          voluptatum. Pariatur. Libero, eius at doloribus, sunt cumque ipsum
          provident placeat sit ea alias, quam reiciendis error omnis mollitia
          eveniet voluptates inventore soluta quia. Iusto ullam corrupti,
          tempora veniam modi sit accusamus? Expedita, eaque aut maiores non
          facilis, id labore delectus officia officiis voluptatibus totam magnam
          reprehenderit reiciendis ipsam maxime excepturi accusamus doloribus
          dolores ut animi rem unde qui? At, suscipit earum! Fuga quos itaque
          modi perspiciatis aspernatur perferendis eveniet earum, debitis totam
          neque excepturi eius fugiat veritatis praesentium exercitationem
          facere ea voluptas, sed animi, beatae quas blanditiis similique
          voluptates. Cum, corporis. Quos neque omnis excepturi ad nostrum
          laborum minus quaerat voluptatem est, officiis dicta saepe alias
          laboriosam ipsa. Molestiae eum quae harum iste cupiditate vitae,
          temporibus sequi ad, reiciendis ea nisi! Facere exercitationem ab
          commodi doloremque cumque optio, nam, alias et minus nostrum qui illo
          veniam, quos minima? Non sequi veniam, fugiat enim cupiditate magnam
          dolorum sed nam voluptates pariatur reiciendis? Est quisquam quibusdam
          fuga nihil commodi laborum libero minus id natus qui similique ea,
          voluptatem amet pariatur ad fugiat nisi vero illo quis rem debitis
          harum! Id impedit enim accusamus. Consectetur in accusamus quasi minus
          maxime officiis qui praesentium ipsa ad, ab expedita eaque eum
          aspernatur soluta atque cum ipsum corrupti rerum officia iusto laborum
          laudantium voluptatibus. Ut, ullam vitae. Nisi recusandae ad nobis
          quidem qui voluptatum sunt quibusdam amet dignissimos voluptates vel
          perferendis, explicabo totam molestiae et incidunt dicta. Obcaecati,
          dolorum eius? Labore fugiat autem, amet unde et rem! Provident,
          laudantium. Qui ipsa quia, accusamus facere dolorum aspernatur
          expedita autem aliquam dolores architecto quam provident quaerat fugit
          delectus sequi assumenda aperiam voluptates cumque quo nemo
          praesentium debitis excepturi. Officia. Aliquam minima consequuntur
          accusamus. Consequuntur sint, sit accusantium provident culpa facere
          obcaecati quia at eligendi totam minima fugit commodi debitis
          cupiditate nostrum, in doloribus non veniam esse est porro
          consectetur. Rem, reprehenderit quas nisi deleniti, quisquam eveniet
          impedit veritatis sed aliquam ratione nihil facere pariatur accusamus
          eligendi beatae quam est adipisci blanditiis quia sint, natus aperiam
          autem! Magnam, esse cum? Rerum, non ratione! Nobis cupiditate quae
          delectus repellendus et inventore officiis iusto modi quam officia nam
          accusamus nesciunt corrupti, consectetur atque aperiam. Sapiente
          explicabo magnam architecto similique. Cumque, vel rerum. Ab et
          dolores corrupti odit, id soluta provident deleniti quo? Numquam
          corporis minima soluta illo cupiditate quidem voluptas amet ad. Id
          earum perferendis laboriosam laborum adipisci eius culpa deserunt vel!
          Consectetur debitis enim, ipsa mollitia quisquam corporis. Non nulla
          eaque quibusdam asperiores veniam inventore placeat nostrum voluptas
          cupiditate praesentium nisi ipsam officiis, adipisci optio quia
          impedit molestias ea obcaecati ipsum. Iusto, perspiciatis voluptate
          accusamus ab maxime laboriosam similique temporibus vero, est
          doloribus soluta corporis excepturi quaerat adipisci, recusandae
          cupiditate vitae autem omnis dolorum. Rerum suscipit itaque, assumenda
          vero iste facilis! Corrupti, dicta, harum eaque impedit quos possimus
          debitis, qui aperiam architecto nemo molestiae. Et, laboriosam
          consequuntur recusandae atque soluta earum impedit ad, magnam quod
          nihil, quis commodi accusantium modi natus. Error natus inventore
          laborum quas, maiores blanditiis fugiat in laudantium corporis vero
          odio a nulla veniam, nihil nam deleniti sed voluptatibus, soluta
          ducimus. Praesentium dolore perspiciatis pariatur modi nihil quo.
          Quasi necessitatibus explicabo ipsa nisi omnis tenetur voluptatibus
          totam a possimus id labore porro consequatur voluptas, velit officiis
          adipisci corporis fugiat cumque consequuntur assumenda iusto. Tempora
          fugiat ipsam distinctio enim? At molestiae voluptatem dolorum modi
          voluptates? Eaque vel praesentium voluptates nemo incidunt laborum a
          asperiores. Ratione tempore labore delectus molestiae error
          blanditiis? Tempore repellendus sit a unde laudantium pariatur minus!
          Ullam numquam laudantium et vel! Deleniti, nihil numquam. Vero, quod,
          eligendi magni pariatur suscipit voluptas magnam quam, repellat
          deleniti facilis perferendis quasi ratione molestias placeat
          reiciendis minus repellendus blanditiis quas. Consequatur recusandae
          nisi accusamus necessitatibus cumque aliquid corporis fugit voluptas
          sunt facilis, doloribus porro, aperiam dolor aut. Impedit eligendi
          labore et fuga maiores consequuntur facilis earum, unde ex accusamus
          laborum. Expedita ea iure esse laudantium minima officiis facere
          voluptatibus amet beatae distinctio, repudiandae perspiciatis
          quisquam, natus at neque dicta vitae voluptate ratione, praesentium
          voluptatum ullam. Commodi accusantium voluptate voluptatum
          repudiandae. Cupiditate dignissimos iure odit veniam asperiores
          consequatur voluptatum cum officia odio quae a, at, vel facere
          temporibus aut assumenda quidem saepe nobis sapiente dolores ut, nemo
          sed. Neque, nihil adipisci? Exercitationem quo officiis placeat
          dolorem nihil tempore delectus doloribus est ullam sit aliquam iure
          adipisci assumenda a sequi, incidunt voluptatibus in illum numquam,
          nam praesentium eaque iusto, enim rem! Iste. Aliquid, consequuntur
          asperiores expedita, sit nobis accusamus itaque consectetur sed,
          officia non tenetur doloribus ex? Porro deleniti aspernatur itaque
          adipisci, corrupti, dolor id quas quasi sapiente totam ut, quod
          temporibus? Excepturi, qui quos saepe temporibus tempora accusantium
          molestias delectus, illum inventore iure, tenetur harum? Consequatur
          est officiis eligendi beatae quis ex neque culpa, ratione architecto
          numquam iure ipsum, similique dolorem. Provident libero in aspernatur!
          Nam pariatur omnis facere nihil ducimus veritatis amet recusandae
          porro, soluta commodi, velit animi ut. Esse provident nostrum quod
          saepe nam laudantium harum eum amet quas. Beatae eius laborum, magnam
          vitae error ducimus deserunt. Repellat debitis unde temporibus placeat
          dicta tempore soluta molestiae, laborum voluptatibus iste eius ducimus
          id repellendus dolorem optio sapiente quas adipisci magni? Itaque,
          ipsum nam. Quas fugiat, dolor doloremque, reprehenderit asperiores
          mollitia adipisci tempore impedit voluptas velit deleniti,
          voluptatibus eveniet doloribus unde perspiciatis vitae pariatur
          molestias error dolore consequatur. Eum, perspiciatis eligendi! Ad
          odit, fugit amet voluptates incidunt repellendus suscipit eos,
          repudiandae ratione mollitia quasi! Laudantium nobis assumenda
          suscipit sit eos animi voluptatibus ducimus alias quae eaque
          necessitatibus, repudiandae, obcaecati porro rerum! Eum atque libero
          illo ut maxime optio? Fugiat mollitia, iure at illum, laudantium,
          quidem deleniti ullam vel recusandae libero labore quis eveniet
          commodi. Dicta ratione repudiandae aliquam, commodi at vero? Tempore
          veritatis, temporibus, sequi corporis nostrum, libero vel dignissimos
          quas officia fuga nemo minus? Praesentium vitae natus, dignissimos hic
          sunt dolorem voluptatem quas nulla eveniet quos, molestias
          perspiciatis a eligendi! Dolores impedit possimus inventore facilis,
          laborum ducimus ratione modi, debitis et eius totam explicabo sed
          laudantium similique ipsum. Dolores, voluptate aperiam atque fugiat
          quaerat sunt quas vitae quo. Sed, magnam. Voluptatum doloremque
          laborum aut id suscipit odit iure nostrum asperiores odio perspiciatis
          vitae, in necessitatibus ex impedit ipsa quasi libero ad unde fugit
          architecto. Facilis architecto dolores officia placeat esse?
          Perferendis odio vitae laborum accusamus? Pariatur quaerat magnam ab
          beatae minima asperiores quisquam, culpa, sequi provident dolores,
          quod ullam porro minus molestias deserunt eum iste. Laudantium
          molestiae unde hic repellendus? Soluta fugiat laborum similique
          accusantium architecto laboriosam enim, illum magnam molestias libero
          obcaecati aperiam pariatur asperiores ducimus amet possimus in
          doloribus nisi expedita maxime veniam officiis quia itaque ratione!
          Corrupti. Odio enim atque et harum minima fugiat tempore mollitia
          voluptas dolor ex iusto aperiam voluptates illum quisquam inventore
          perspiciatis, hic sunt non. Praesentium ad temporibus nesciunt. Quidem
          voluptate distinctio voluptas? Qui magni, dicta et blanditiis natus
          reprehenderit ipsum. Aperiam dignissimos maiores beatae perspiciatis
          expedita doloribus, possimus nemo distinctio fugiat, ipsam omnis
          molestiae? Magni voluptas atque facilis ab iure quod doloribus.
          Accusantium assumenda consequatur, dolores sed voluptas cumque autem
          numquam sit dignissimos et culpa molestiae ipsa corporis? Distinctio
          nisi nobis nostrum laborum reiciendis at, ipsam maiores eaque
          praesentium eveniet quas pariatur! Voluptatem similique, ullam aut
          fugit molestias reiciendis incidunt inventore dolor veniam, culpa et
          delectus nam, provident fuga sapiente obcaecati harum libero! Vel
          voluptas nesciunt delectus itaque, laudantium earum veniam debitis?
          Deleniti consectetur velit cupiditate nulla labore doloremque tenetur
          recusandae obcaecati odit eius minima, consequuntur officiis modi
          quae, voluptatibus soluta dicta tempora mollitia! Obcaecati magni
          debitis quidem! Perferendis eos obcaecati explicabo. Perferendis
          laudantium nesciunt veritatis doloribus obcaecati rerum voluptate.
          Vero, iste. Quasi architecto blanditiis libero ut asperiores quae,
          tenetur ab suscipit debitis error vero ratione veritatis eum
          temporibus voluptatibus, pariatur perferendis. Tempora ipsum quod non
          labore ea dicta iure eius sed facilis magni illum sequi eos quam,
          autem consequatur veniam aperiam, a id commodi repellendus fugiat
          aliquid ullam saepe! Ipsam, sapiente!
        </MText>
      </MDialog>
    </div>
  )
}

function MDialogNestedDemo() {
  const [firstDialogOpen, setFirstDialogOpen] = useState(false)
  const [secondDialogOpen, setSecondDialogOpen] = useState(false)

  return (
    <div>
      <button onClick={() => setFirstDialogOpen(true)}>
        Open first dialog
      </button>
      <MDialog
        title="First dialog"
        description="This is a dialog with nested dialogs"
        open={firstDialogOpen}
        onClose={() => setFirstDialogOpen(false)}
      >
        <button onClick={() => setSecondDialogOpen(true)}>
          Open second dialog
        </button>
        <MDialog
          title="Second dialog"
          open={secondDialogOpen}
          onClose={() => setSecondDialogOpen(false)}
          onConfirm={() => setSecondDialogOpen(false)}
        >
          <MDialogRegularDemo />
        </MDialog>
      </MDialog>
    </div>
  )
}
