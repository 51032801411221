import { useState } from "react"

import { useGetUser } from "src/data/user/hooks/useGetUser"
import { DatePickerDropdown } from "src/ui/DateRangePicker/DatePickerDropdown"
import { DateRangeDropdown } from "src/ui/DateRangePicker/DateRangeDropdown"
import {
  DateRangePicker,
  TDateRange,
} from "src/ui/DateRangePicker/DateRangePicker"
import { MTimePicker } from "src/ui/MTimePicker/MTimePicker"

export function DateTimeDemo() {
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [time, setTime] = useState<Date | null>(null)
  const [required, setRequired] = useState(false)

  const { clock_type: clockType } = useGetUser()

  function clearDates() {
    setStartDate(null)
    setEndDate(null)
    setTime(null)
  }

  return (
    <div style={{ display: "grid", gap: "1rem" }}>
      <div style={{ display: "flex", gap: "1rem" }}>
        <label>
          Required
          <input
            type="checkbox"
            checked={required}
            onChange={(e) => setRequired(e.target.checked)}
          />
        </label>

        <button onClick={clearDates}>Clear dates state</button>
      </div>

      <div>
        <div>startDate: {startDate?.toString()}</div>
        <div>endDate: {endDate?.toString()}</div>
      </div>

      <div>DatePickerDropdown</div>
      <DatePickerDropdown
        date={startDate}
        setDate={setStartDate}
        required={required}
      />

      <div>DateRangeDropdown</div>
      <DateRangeDropdown
        dateRange={{ startDate, endDate }}
        setDateRange={(dr: TDateRange): void => {
          setStartDate(dr.startDate)
          setEndDate(dr.endDate)
        }}
        required={required}
      />

      <div>DateRangePicker</div>
      <DateRangePicker
        startDate={startDate}
        endDate={endDate}
        onDateRangeChange={(dr: TDateRange) => {
          setStartDate(dr.startDate)
          setEndDate(dr.endDate)
        }}
        dateRangeProps={{}}
      />

      <div>TimePicker</div>
      <MTimePicker
        label={"Select time"}
        time={time}
        setTime={setTime}
        clockType={clockType}
        required={required}
      />
    </div>
  )
}
