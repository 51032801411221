import styled from "styled-components"

import {
  containerDemoVariables,
  Item,
} from "src/components/Sandbox/ContainerQueriesDemo/common"
import { Container } from "src/ui/Container/Container"
import { spacing } from "src/ui/spacing"

export function CssInJsContainerQuery() {
  return (
    <StyledContainer containerType="size">
      <ResponsiveGrid>
        <MainChild></MainChild>
        <Item></Item>
        <Item></Item>
      </ResponsiveGrid>
    </StyledContainer>
  )
}

const StyledContainer = styled(Container)`
  resize: horizontal;
  border: 1px solid black;
  width: 100%;
  min-height: 300px;
  overflow: auto;
  display: grid;
  align-content: center;
  padding: ${spacing.M};
`

const ResponsiveGrid = styled.div`
  display: grid;
  min-height: 200px;
  min-height: 100cqh;
  min-width: 100%;
  gap: ${containerDemoVariables.narrowGap};
  padding: ${containerDemoVariables.narrowPadding};

  @container (width > 400px) {
    & {
      grid-template-columns: ${containerDemoVariables.wideGridCols};
      gap: ${containerDemoVariables.wideGap};
      padding: ${containerDemoVariables.widePadding};
    }
  }
`

const MainChild = styled.div`
  display: grid;
  place-content: center;
  background-color: ${containerDemoVariables.narrowChildColor};
  &::before {
    content: ${containerDemoVariables.mainChildContent};
  }

  @container (width > 400px) {
    & {
      grid-row: ${containerDemoVariables.wideChildRows};
      background-color: ${containerDemoVariables.wideChildColor};
    }
  }
`
