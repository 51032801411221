import { useEffect, useState } from "react"

import { SerialMessage } from "src/components/RemoteDebugService/SerialContext"
import { SerialDeviceLog } from "src/components/RemoteDebugService/SerialDeviceLog"
import { useSerial } from "src/components/RemoteDebugService/useSerial"
import { MBanner } from "src/ui/MBanner/MBanner"
import { Logger } from "src/utils/logger"

const debug = new Logger({ prefix: "[WebSerialDemo]: " })

const DEVICE_INFO_REQ = [1, 1, 2, 5, 2, 34, 4, 136, 1, 8, 0]

export function WebSerialDemo() {
  const {
    isConnected,
    connect,
    disconnect,
    subscribe,
    unsubscribe,
    portState,
    writableStream,
    canUseSerial,
    autoConnectEnabled,
    setAutoConnectEnabled,
  } = useSerial()
  const [data, setData] = useState<string[]>([])

  function sendData() {
    if (!writableStream) return
    const data = Uint8Array.from(DEVICE_INFO_REQ).buffer
    // was `const data = Uint8Array.from(DEVICE_INFO_REQ)`
    debug.log("send", data)
    const writer = writableStream.getWriter()
    writer.write(data)
    setData((log) => [...log, `> ${data}\n`])
    writer.releaseLock()
  }

  function receiveData(m: SerialMessage) {
    debug.log("recv", m)
    setData((log) => [...log, `${m.value}\n`])
  }

  // This effect adds a subscriber whenever a device is connected; works both
  // with manual and auto-connect. Whenever this component is unmounted we'll
  // also remove our subcsriber.
  useEffect(() => {
    if (isConnected) {
      debug.log("Adding subscribe callback")
      const subscriberId = subscribe("WebSerialDemo", receiveData)
      const onUnmount = () => {
        debug.log("unsubscribing", subscriberId)
        unsubscribe(subscriberId)
      }
      return () => onUnmount()
    }
  }, [isConnected, subscribe, unsubscribe])

  if (!canUseSerial) {
    return (
      <MBanner>
        Your browser does not support connecting to a Minut device
      </MBanner>
    )
  }

  return (
    <article style={{ display: "grid", gap: "1rem" }}>
      <div>Port state: {portState}</div>
      <label>
        Auto connect{" "}
        <input
          type="checkbox"
          checked={autoConnectEnabled}
          onChange={() => setAutoConnectEnabled(!autoConnectEnabled)}
        />
      </label>
      <button disabled={isConnected} onClick={connect}>
        Connect
      </button>
      <button disabled={!isConnected && false} onClick={disconnect}>
        Disconnect
      </button>
      <button disabled={!isConnected} onClick={sendData}>
        Send data
      </button>

      <div style={{ maxWidth: "75ch" }}>
        <SerialDeviceLog
          data={data}
          onClear={() => setData([])}
          open={isConnected}
        />
      </div>
    </article>
  )
}
