import { useState } from "react"
import styled from "styled-components"

import { MSkeleton, MSkeletonVariant } from "src/ui/MSkeleton/MSkeleton"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function MSkeletonDemo() {
  const [variant, setVariant] = useState<MSkeletonVariant>("rect")
  const [width, setWidth] = useState(60)
  const [height, setHeight] = useState(40)

  return (
    <div>
      <div>
        <MText variant="heading3" marginBottom={spacing.M}>
          Playground
        </MText>
        <Configurator>
          <InputWrapper>
            <label htmlFor="variant">Variant</label>
            <select
              id="variant"
              value={variant}
              onChange={(e) => setVariant(e.target.value as MSkeletonVariant)}
            >
              <option>text</option>
              <option>rect</option>
              <option>circle</option>
            </select>
          </InputWrapper>
          <InputWrapper>
            <label htmlFor="width">Width</label>
            <input
              id="width"
              type="number"
              value={width}
              onChange={(e) => setWidth(parseInt(e.target.value))}
            />
          </InputWrapper>
          <InputWrapper>
            <label htmlFor="height">Height</label>
            <input
              id="height"
              type="number"
              value={height}
              onChange={(e) => setHeight(parseInt(e.target.value))}
            />
          </InputWrapper>
        </Configurator>
        <br />
        <MSkeleton variant={variant} width={width} height={height} />
      </div>
      <br />
      <div>
        <MText variant="heading3" marginBottom={spacing.M}>
          Defaults
        </MText>
        <MSkeleton />
        <br />
        <MSkeleton variant="rect" />
        <br />
        <MSkeleton variant="circle" />
      </div>
    </div>
  )
}

const Configurator = styled.div`
  display: flex;
  gap: ${spacing.M};
`

const InputWrapper = styled.div`
  display: grid;
`
