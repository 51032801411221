import { useState } from "react"

import { useQueryClient } from "@tanstack/react-query"

import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { orgsKeys } from "src/data/organizations/queries/organizationQueryCache"
import {
  MinutQueryFilter,
  useMinutPatch,
  useMinutQuery,
} from "src/hooks/minutQuery"

export function MinutQueryDemo() {
  const { orgId } = useOrganization()
  const [newHomeName, setNewHomeName] = useState("")
  const [homeIdOverride, setHomeIdOverride] = useState("")

  const fetchHomes = useFetchHomes({
    organization_id: orgId,
    filters: { limit: 3 },
  })
  const fetchHome = useFetchHome({ orgId, homeId: homeIdOverride })
  const fetchHomeName = useFetchHomeName({ orgId, homeId: homeIdOverride })
  const patchHome = usePatchHome()

  function handleChangeHomeName() {
    const home_id = homeIdOverride
    if (!home_id) return

    patchHome.mutate(
      {
        path: { organization_id: orgId, home_id },
        body: { name: newHomeName },
      },
      {
        onSuccess: () => {
          setNewHomeName("")
        },
        onError(error) {
          if (error.response?.data.error_key === "access_forbidden") {
            // here we can handle forbidden access
          }
          if (error.response?.data.error_key === "home_not_found") {
            // here we can handle errors when the home was not found
          }
        },
      }
    )
  }

  const homeAccessForbidden =
    fetchHome.error?.response?.data.error_key === "access_forbidden"
  const mutatedHomeNotFound =
    patchHome.error?.response?.data.error_key === "home_not_found"

  return (
    <form
      onSubmit={(e) => e.preventDefault()}
      style={{ display: "inline-grid", gap: "1rem" }}
    >
      <div>
        {fetchHomes.data?.homes?.map((h) => (
          <div key={h.home_id}>
            <span>
              {h.home_id}: {h.name}
            </span>
          </div>
        ))}
      </div>

      <label>
        Target home id:{" "}
        <input
          value={homeIdOverride}
          onChange={(e) => setHomeIdOverride(e.target.value)}
        />
      </label>

      <label>Current Home name: {fetchHomeName.data || "???"}</label>
      <label>
        New home name:{" "}
        <input
          value={newHomeName}
          onChange={(e) => setNewHomeName(e.target.value)}
          required
        />
      </label>
      <button
        onClick={handleChangeHomeName}
        type="submit"
        disabled={patchHome.isLoading || homeAccessForbidden}
      >
        Submit new home name
      </button>
      {homeAccessForbidden && <div>You do not have access to this home</div>}
      {mutatedHomeNotFound && <div>Error! Home not found</div>}
    </form>
  )
}

function usePatchHome() {
  const queryClient = useQueryClient()

  return useMinutPatch<
    "/organizations/{organization_id}/homes/{home_id}",
    "403"
  >({
    pathFn: (p) => `/organizations/${p.organization_id}/homes/${p.home_id}`,
    options: {
      onSuccess(data, _variables) {
        // handle successful mutation result, e.g., update cache, dependent queries etc
        return Promise.all([
          queryClient.invalidateQueries(orgsKeys.homeDetails(data.home_id)),
          queryClient.invalidateQueries(
            orgsKeys.homeLists(data.organization_id)
          ),
        ])
      },
    },
  })
}

function useFetchHomes(p: {
  organization_id: string
  filters: MinutQueryFilter<`/organizations/{organization_id}/homes`> // A utility type to get the filters typed from the path
}) {
  return useMinutQuery<`/organizations/{organization_id}/homes`, "403">({
    queryKey: orgsKeys.homeLists(p.organization_id),
    queryPath: `/organizations/${p.organization_id}/homes`,
    filters: p.filters,
  })
}

function useFetchHome({
  homeId,
  orgId,
}: {
  orgId: string
  homeId: string | undefined
}) {
  return useMinutQuery<
    `/organizations/{organization_id}/homes/{home_id}`, // auto-completed `apiPath` can be copied here
    "403"
  >({
    apiPath: "/organizations/{organization_id}/homes/{home_id}", // can be inferred & auto-completed
    queryPath: `/organizations/${orgId}/homes/${homeId}`, //This path is typed with the help of the passed generic, for example, setting "homes2" instead of "homes" will cause a type error
    queryKey: orgsKeys.homeDetails(homeId ?? ""),
    options: { enabled: !!orgId && !!homeId },
  })
}

/** Demo useMinutQuery with `select` */
function useFetchHomeName({
  orgId,
  homeId,
}: {
  orgId: string
  homeId: string
}) {
  return useMinutQuery<
    `/organizations/{organization_id}/homes/{home_id}`,
    "403",
    string | undefined
  >({
    queryPath: `/organizations/${orgId}/homes/${homeId}`,
    queryKey: orgsKeys.homeDetails(homeId ?? ""),
    options: {
      enabled: !!orgId && !!homeId,
      select: (data) => data.name,
    },
  })
}
